<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="550px"
  >
    <div class="rules-form">
      <div class="rule-title">
        <img class="tip_ico" src="@/assets/images/ico_ts_red.png" alt />
        <span>说明：门店、预留返利和店员差价返利之和不可高于弹性返利</span>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
      >
        <div class="set-title">
          <span style="color: #0981ff;font-weight: 500;font-size: 16px;line-height: 16px;">
            常规返利设置
          </span>
        </div>
        <el-form-item label="报价区间:" required>
          <el-col :span="11">
            <el-form-item prop="lower">
              <el-input
                size="small"
                v-model.trim="ruleForm.lower"
                oninput="this.value=value.replace(/[^0-9]/g,'')"
                placeholder="输入起始值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col style="text-align: center" :span="2">~</el-col>
          <el-col :span="11">
            <el-form-item prop="upper">
              <el-input
                size="small"
                v-model.trim="ruleForm.upper"
                oninput="this.value=value.replace(/[^0-9]/g,'')"
                placeholder="输入结束值"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="返利比例:" prop="rebate">
          <el-input
            size="small"
            placeholder="输入返利比例"
            :value="ruleForm.rebate"
            @input="(v) => ruleForm.rebate = numberCheck(v)"
            @blur="ruleForm.rebate = numberFn(ruleForm.rebate)"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="返利预留比例:" prop="delayRebate">
          <el-input
            placeholder="输入返利预留比例"
            size="small"
            :value="ruleForm.delayRebate"
            @input="(v) => ruleForm.delayRebate = numberCheck(v)"
            @blur="ruleForm.delayRebate = numberFn(ruleForm.delayRebate)"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>

        <el-form-item label="返利上限:" required>
          <el-row type="flex" justify="space-between">
            <el-col :span="11">
              <el-form-item prop="rebateLimit">
                <el-input
                  size="small"
                  v-model.trim="ruleForm.rebateLimit"
                  oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入门店返利上限金额"
                  @input="
                    () => {
                      if (ruleForm.rebate === 0) {
                        ruleForm.rebateLimit = 0;
                      }
                      if (ruleForm.rebateLimit) {
                        ruleForm.rebateLimit = Number(ruleForm.rebateLimit);
                      }
                    }
                  "
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item prop="delayRebateLimit">
                <el-input
                  size="small"
                  v-model.trim="ruleForm.delayRebateLimit"
                  placeholder="输入预留返利上限金额"
                  maxlength="5"
                  @input="
                    (v) => {
                      if (ruleForm.delayRebate === 0) {
                        ruleForm.delayRebateLimit = 0;
                      }
                      if (ruleForm.delayRebateLimit) {
                        ruleForm.delayRebateLimit = Number(
                          ruleForm.delayRebateLimit
                        );
                      }
                    }
                  "
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <div class="set-title flex">
          <span
            style="
              width: 170px;
              color: #0981ff;
              font-weight: 500;
              font-size: 16px;
              line-height: 16px;
            "
            >差价返利弹性设置</span
          >
          <span
            style="
              color: #ff8080;
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
            "
            >设置差价返利弹性比例后，店员差价不可超过弹性比例或弹性限额</span
          >
        </div>
        <el-form-item label="弹性比例:" prop="cutPriceScope">
          <el-input
            placeholder="输入弹性比例"
            size="small"
            :value="ruleForm.cutPriceScope"
            @input="(v) => ruleForm.cutPriceScope = numberCheck(v)"
            @blur="ruleForm.cutPriceScope = numberFn(ruleForm.cutPriceScope)"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="弹性上限:" prop="cutPriceScopeLimit">
          <el-input
            size="small"
            v-model.trim="ruleForm.cutPriceScopeLimit"
            oninput="this.value=value.replace(/[^0-9]/g,'')"
            placeholder="输入弹性上限金额"
            @input="
              () => {
                if (ruleForm.cutPriceScope === 0) {
                  ruleForm.cutPriceScopeLimit = 0;
                }
                if (ruleForm.cutPriceScopeLimit) {
                  ruleForm.cutPriceScopeLimit = Number(
                    ruleForm.cutPriceScopeLimit
                  );
                }
              }
            "
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click.native="close">取消</el-button>
      <el-button type="primary" @click.native="handleSuccess">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>

const originFormData = {
  lower: "",
  upper: "",
  rebate: "",
  delayRebate: "",
  shopownerReward: "",
  clerkReward: "",
  rebateType: "",
  shopownerRewardLimit: "",
  clerkRewardLimit: "",
  rebateLimit: "",
  delayRebateLimit: "",
  cutPriceScope: "",
  cutPriceScopeLimit: ""
}
export default {
  name: "RegionDia",
  data() {
    return {
      dialogVisible: false,
      title: '',
      ruleForm: originFormData,
      rules: {
        lower: [
          { required: true, message: "请输入报价区间起始值", trigger: "blur" },
        ],
        upper: [
          { required: true, message: "请输入报价区间结束值", trigger: "blur" },
        ],
        rebate: [
          { required: true, message: "请输入返利比例", trigger: "blur" },
        ],
        delayRebate: [
          { required: true, message: "请输入返利预留比例", trigger: "blur" },
        ],
        rebateType: [
          { required: true, message: "请选择返利方式", trigger: "change" },
        ],
        shopownerReward: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        clerkReward: [{ required: true, message: "请输入", trigger: "blur" }],
        shopownerRewardLimit: [
          { required: true, message: "输入店长红包上限金额", trigger: "blur" },
        ],
        clerkRewardLimit: [
          { required: true, message: "输入店员红包上限金额", trigger: "blur" },
        ],
        delayRebateLimit: [
          { required: true, message: "输入预留返利上限金额", trigger: "blur" },
        ],
        rebateLimit: [
          { required: true, message: "输入门店返利上限金额", trigger: "blur" },
        ],
        cutPriceScope: [
          { required: true, message: "请输入弹性比例", trigger: "blur" },
        ],
        cutPriceScopeLimit: [
          { required: true, message: "输入弹性上限金额", trigger: "blur" },
        ]
      },
    };
  },
  methods: {
    open(formData) {
      if (formData) {
        this.ruleForm = JSON.parse(JSON.stringify(formData))
        this.title = '编辑规则-返利设置'
      } else {
        this.ruleForm = JSON.parse(JSON.stringify(originFormData))
        this.title = '添加规则-返利设置'
      }
      this.$nextTick(() => {
        this.$refs["ruleForm"]?.clearValidate()
        this.dialogVisible = true
      })
    },
    close() {
      this.dialogVisible = false
    },
    handleSuccess() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          const { lower, upper } = this.ruleForm
          if (Number(lower) >= Number(upper)) {
            this.$message.error("结束值需大于起始值");
            return;
          }
          this.$emit('confirm', this.ruleForm)
        }
      })
    },
    numberCheck(v) {
      var str = v
      var len1 = str.substr(0, 1);
      var len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
        }
      }
      //正则替换，保留数字和小数点
      str = str.match(/^\d*(\.?\d{0,1})/g)[0] || null;
      return str
    },
    numberFn(v) {
      if(!v) return
      return Number(v)
    },
    numberFun(v) {
      if (!v) return
      v = Number(v)
    }
  }
};
</script>

<style lang="scss" scoped>
.rules-form {
  .rule-title {
    display: flex;
    align-items: center;
    color: #ff8080;
    margin-bottom: 20px;
  }
  .tip_ico {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
  /deep/.el-input-group__append {
    border: 1px solid #0981ff;
    background: #0981ff;
    color: #ffffff;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.set-title {
  font-weight: bold;
  color: #333333;
  font-size: 16px;
  height: 16px;
  padding-left: 10px;
  border-left: 3px solid #0981ff;
  margin-bottom: 10px;
}
</style>