<template>
  <el-dialog
    title="删除确认"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="380px"
  >
    <div class="del-title">
      <div class="sure_tip">确认要删除该数据吗？</div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click.native="visible=false">取消</el-button>
      <el-button type="primary" @click.native="confirm" >确定</el-button>
       <!-- :loading="butLoading" -->
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    confirm() {
      this.$emit('confirm')
      this.visible = false
    }
  }
}
</script>