<template>
  <div>
    <el-table v-loading="loading" :data="data" border>
      <!-- :currentPage="rebatePage.pageNum"
        :total="rebatePage.total"
        :pageSize="rebatePage.pageSize"
        @handleCurrentChange="rebateHandleCurrentChange" -->
      <el-table-column type="index" label="序号" align="center" />
      <el-table-column label="报价区间" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
        </template>
      </el-table-column>
      <el-table-column label="门店返利" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.rebate }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="门店返利上限" align="center">
        <template slot-scope="{ row }">
          <span>￥{{ row.rebateLimit }}</span>
        </template>
      </el-table-column>
      <el-table-column label="预留返利" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.delayRebate }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="预留返利上限" align="center">
        <template slot-scope="{ row }">
          <span>￥{{ row.delayRebateLimit }}</span>
        </template>
      </el-table-column>
      <el-table-column label="差价利润弹性比例" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.cutPriceScope }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="差价利润弹性上限" align="center">
        <template slot-scope="{ row }">
          <span>￥{{ row.cutPriceScopeLimit }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="160px" >
        <template slot-scope="{ row, $index }">
          <el-button
            size="mini"
            type="primary"
            round
            @click="handleBtn('edit', $index, row)"
            >
            编辑
          </el-button>
          <el-button
            size="mini"
            type="danger"
            round
            @click="handleBtn('del', $index, row)"
            >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <RegionDia ref="regionDia" @confirm="handleEdit" />
    <RegionDelDia ref="regionDelDia" @confirm="handleDel" />
  </div>
</template>

<script>
import RegionDelDia from '../dialog/RegionDelDia.vue'
import RegionDia from '../dialog/RegionDia.vue'
export default {
  name: 'RegionListTable',
  components: { RegionDia, RegionDelDia },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // data: []
      selectIndex: 0
    }
  },
  methods: {
    handleBtn(type, index, row) {
      this.selectIndex = index
      if (type === 'edit') {
        this.$refs['regionDia'].open(row)
      } else {
        this.$refs['regionDelDia'].open()
      }
    },
    handleEdit(form) {
      const data = JSON.parse(JSON.stringify(this.data))
      data[this.selectIndex] = form
      this.$emit('change', data)
      this.$refs['regionDia'].close()
    },
    handleDel() {
      const data = JSON.parse(JSON.stringify(this.data))
      data.splice(this.selectIndex, 1)
      this.$emit('change', data)
    },
  }
}
</script>