<template>
  <el-dialog
    title="设置指定机型"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="1300px"
    @close="btnStoreClose"
  >
    <GlobalForm
      v-if="showForm"
      :form-item-list="formItemList"
      :inline="true"
      round
      confirmBtnName="查询"
      @handleConfirm="search"
    />
      <!-- <el-form-item label="所属分类">
        <el-select v-model="modelform.machineTypeId" placeholder="请选择分类">
          <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属品牌">
        <el-select v-model="modelform.brandId" filterable placeholder="请选择品牌">
          <el-option v-for="ele in oldBrand" :key="ele.id" :label="ele.name" :value="ele.id"></el-option>
        </el-select>
      </el-form-item> -->
    <!-- <TableTransfer v-model="ids" :data="dataList" :filterTableData="this.filterTableData" leftName="添加机型" rightName="已添加机型">
      <el-table-column label="分类" prop="typeName" align="center" />
      <el-table-column label="品牌" prop="brandName" align="center" />
      <el-table-column label="机型名称" prop="name" width="160" align="center" />
      <el-table-column label="机型来源" prop="channel" width="150" align="center" />
    </TableTransfer> -->
    <div style="display: flex; justify-content: space-between">
      <el-card>
        <div style="font-weight: bold; height: 40px">添加机型</div>
        <GlobalTable
          ref="leftTable"
          v-loading="leftLoading"
          :columns="tableColumns"
          :data="leftList"
          :currentPage="leftResult.current"
          :total="leftResult.total"
          :pageSize="pageSize"
          :max-height="500"
          isSelection
          @handleSelectionChange="(v) => (leftSelectList = v)"
          @handleCurrentChange="getLeftData"
          ></GlobalTable>
        <!-- <el-table
          v-loading="leftLoading"
          ref="leftTable"
          :data="leftList"
          border
          max-height="500"
          @selection-change="(v) => (leftSelectList = v)"
        >
          <el-table-column type="selection" align="center" />
          <el-table-column label="序号" type="index" align="center" />
          <el-table-column label="分类" prop="typeName" align="center" />
          <el-table-column label="品牌" prop="brandName" align="center" />
          <el-table-column label="机型名称" prop="name" width="160" align="center" />
          <el-table-column label="机型来源" prop="channel" width="150" align="center" />
        </el-table> -->
      </el-card>
      <div class="arrows-box">
        <i
          :disabled="!rightSelectList.length"
          class="arrow-btn left"
          @click="rightSelectList.length && move('left')"
        />
        <i
          :disabled="!leftSelectList.length"
          class="arrow-btn right"
          @click="leftSelectList.length && move('right')"
        />
      </div>
      <el-card>
        <div style="font-weight: bold; height: 40px">
          已添加机型（{{ idList.length }}个）
        </div>
        <GlobalTable
          ref="rightTable"
          v-loading="rightLoading"
          :columns="tableColumns"
          :data="rightList"
          :currentPage="rightResult.current"
          :total="rightResult.total"
          :pageSize="pageSize"
          :max-height="500"
          isSelection
          @handleSelectionChange="(v) => (rightSelectList = v)"
          @handleCurrentChange="getRightData"
          ></GlobalTable>
        <!-- <el-table
          v-loading="rightLoading"
          ref="rightTable"
          :data="rightList"
          border
          max-height="500"
          @selection-change="(v) => (rightSelectList = v)"
        >
          <el-table-column type="selection" align="center" />
          <el-table-column label="序号" type="index" align="center" />
          <el-table-column label="分类" prop="typeName" align="center" />
          <el-table-column label="品牌" prop="brandName" align="center" />
          <el-table-column label="机型名称" prop="name" width="160" align="center" />
          <el-table-column label="机型来源" prop="channel" width="150" align="center" />
        </el-table> -->
      </el-card>
    </div>
    <div slot="footer">
      <el-button size="small" @click="btnStoreClose">关闭</el-button>
      <el-button size="small" type="primary" @click="confirm">确认添加</el-button>
    </div>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
// import Pagination from '../../../../../components/common/Pagination.vue';
export default {
  // components: { Pagination },
  name: "MachineListDia",
  props: {
    machineIds: {
      type: Array,
      default: () => [],
    },
    // 左请求列表忽略的id（已选）
    omitIds: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      dialogVisible: false,
      // 搜索条件相关
      showForm: false,
      filterData: {},
      formItemList: [
        {
          labelName: "选择分类",
          key: "machineTypeId",
          type: "selectFilterable",
          placeholder: "请选择分类",
          clear: true,
          option: []
        },
        {
          labelName: "选择品牌",
          key: "brandId",
          type: "selectFilterable",
          placeholder: "请选择品牌",
          clear: true,
          option: []
        },
        {
          labelName: "机型名称",
          key: "name",
          type: "input",
          placeholder: "请输入机型名称",
        },
      ],
      // Table数据相关，list展示的数据，SelectList选中的列表，Result接口返回
      rightList: [],
      leftList: [],

      leftSelectList: [],
      rightSelectList: [],

      leftLoading: false,
      rightLoading: false,

      leftResult: {
        total: 0,
        current: 1
      },
      rightResult: {},

      pageSize: 10,
      leftTotal: 0,
      rightTotal: 0,
      tableColumns: [
        { label: '分类', prop: "typeName" },
        { label: '品牌', prop: "brandName" },
        { label: '机型名称', prop: "name" },
        { label: '机型来源', prop: "channel" },
      ],

      // 选中的id列表
      idList: [],
    };
  },
  created() {
    this.getClassList()
    this.getResidentList()
  },
  methods: {
    open() {
      // 清空筛选
      this.filterData = {}
      this.showForm = true
      this.idList = JSON.parse(JSON.stringify(this.machineIds)) || []
      this.dialogVisible = true;
      this.getData()
    },
    getData() {
      this.getLeftData()
      this.getRightData()
    },
    getLeftData(pageNum = 1) {
      this.leftLoading = true
      _api.listByTypeBrandPage({ 
        ...this.filterData,
        type: '01',
        assignMachineModelIds: [...this.omitIds, ...this.idList],
        pageNum,
        pageSize: this.pageSize
      }).then(res => {
        this.leftResult = res.data
        this.leftList = res.data.records || []
      }).finally(() => {
        this.leftLoading = false
      })
    },
    getRightData(pageNum = 1) {
      this.rightLoading = true
      _api.listByTypeBrandPage({
        ...this.filterData,
        type: '02',
        assignMachineModelIds: this.idList,
        pageNum,
        pageSize: this.pageSize
      }).then(res => {
        this.rightResult = res.data
        this.rightList = res.data.records || []
      }).finally(() => {
        this.rightLoading = false
      })
    },
    // 获取分类集合
    getClassList() {
      _api.selectList().then(res => {
        const list = res.data.map(item => ({
          value: item.id,
          label: item.name
        }))
        this.formItemList[0].option = list
      })
    },
    // 获取旧机品牌列表
    getResidentList() {
      _api.ResidentList().then(res => {
        const list = res.data.map(item => ({
          value: item.id,
          label: item.name
        }))
        this.formItemList[1].option = list
      })
    },
    search(v) {
      this.filterData = v
      this.getData()
    },
    confirm() {
      this.$emit(
        "confirm",
        JSON.parse(JSON.stringify(this.idList))
      );
      this.btnStoreClose();
    },
    btnStoreClose() {
      this.dialogVisible = false;
      this.showForm = false
    },
    move(type) {
      // 数据移动
      const selectList =
        type === "left" ? this.rightSelectList : this.leftSelectList;
      const selectIds = selectList.map((item) => item.id);
      if (type === "right") {
        this.rightList.push(...selectList);
        this.leftList = this.leftList.filter(
          (item) => !selectIds.includes(item.id)
        );
        this.idList = [...this.idList, ...selectIds]
      } else {
        this.leftList.push(...selectList);
        this.rightList = this.rightList.filter(
          (item) => !selectIds.includes(item.id)
        );
        this.idList = this.idList.filter((id) => !selectIds.includes(id))
      }
      // this.$emit('input', this.rightList.map(item => item.id))
    },
  },
};
</script>

<style lang="scss" scoped>
.arrows-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  .arrow-btn {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 2px 6px 6px 0px rgba(102, 102, 102, 0.16);
    &[disabled] {
      cursor: not-allowed;
      &.left {
        background: url("~@/assets/images/storeshop/icon_hs_zj.png");
      }
      &.right {
        background: url("~@/assets/images/storeshop/icon_hs_yj.png");
      }
    }
    &.left {
      background: url("~@/assets/images/storeshop/icon_qhs_zj.png");
    }
    &.right {
      margin-top: 16px;
      background: url("~@/assets/images/storeshop/icon_ls_yj.png");
    }
  }
}
</style>