<template>
  <div class="class-list">
    <GlobalInfoBar
      icon="custom"
      :title="source=='online'?'支付设置':'设置利润模式'"
      :content="source=='online'?'设置门店各个合作商家的返利比例、店长红包、店员红包，设置完成后会自动推送给门店创建者，待其同意后则可正式启用线上支付功能':'修改利润模式前请先与门店商家沟通，修改利润模式将涉及前端报价比例的变动，以及支付利润的计算，请谨慎修改。'"
    >
        <img class="info-bar_icon" src="@/assets/images/icon_pay_set.png" alt />
    </GlobalInfoBar>
    <!-- <Info/> -->
    <GlobalChunk icon="vertical">
      <Table />
    </GlobalChunk>
  </div>
</template>

<script>
import Table from "./Table";
export default {
  name: "class-list",
  components: {
    Table
  },
  data() {
    return {
      source: 'online'
    };
  },
  created(){
    this.source = this.$route.query.source
  },
    methods: {},
};
</script>

<style>
</style>