<template>
  <div>
    <div class="set-title flex">
      <div><span>{{ templateName }}</span></div>
      <el-link type="primary" style="margin-left: 20px" @click="$refs['regionDelDia'].open()">删除该配置</el-link>
    </div>
    <div class="flex flex-jb" style="margin-bottom: 10px">
      <div class="faultBtn">
        <div :class="rebateTypeTab === '01' ? 'active' : 'none'" @click="rebateBtnClick('01')">
          比例设置
        </div>
        <div :class="rebateTypeTab === '02' ? 'active' : 'none'" @click="rebateBtnClick('02')">
          机型设置
        </div>
      </div>
      <el-button
        size="small"
        v-if="rebateTypeTab === '01'"
        type="primary"
        icon="el-icon-plus"
        @click="$refs['regionDia'].open()"
      >添加规则</el-button>
    </div>
    <!-- 比例设置 -->
    <RegionListTable v-if="rebateTypeTab == '01'" :data="regionList" @change="regionListChange" />
    <div v-if="rebateTypeTab == '02'">
      <span style="font-size: 15px; font-weight: bold;margin-right: 15px">已设置{{ machineIds.length }}个机型</span>
      <el-link
        type="primary"
        :underline="false"
        @click="openMachineListDia"
        >管理机型</el-link
      >
    </div>
    <!-- 新增规则 -->
    <RegionDia ref="regionDia" @confirm="addRegion" />
    <!-- 管理机型 -->
    <MachineListDia ref="machineListDia" :omitIds="omitIds" :machineIds="machineIds" @confirm="machineIdsChange" />
    <RegionDelDia ref="regionDelDia" @confirm="$emit('del')" />
  </div>
</template>

<script>
import RegionListTable from '../table/RegionListTable.vue';
import MachineListDia from "./MachineListDia.vue";
import RegionDia from '../dialog/RegionDia.vue';
import RegionDelDia from '../dialog/RegionDelDia.vue';
export default {
  name: "MachineTemplateList",
  components: {
    MachineListDia,
    RegionListTable,
    RegionDia,
    RegionDelDia
  },
  props: {
    templateName: String,
    regionList: {
      type: Array,
      default: () => []
    },
    machineIds: {
      type: Array,
      default: () => []
    },
    index: Number,
    machineTemplateList: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      rebateTypeTab: "01",
      omitIds: []
    };
  },
  methods: {
    rebateBtnClick(type) {
      this.rebateTypeTab = type;
    },
    addRegion(data) {
      const { regionList } = this
      // 只有新增判断不连续
      if (regionList && regionList.length) {
        const lastItem = regionList[regionList.length - 1]
        if (data.lower != lastItem.upper) {
          this.$message.error("价位区间不连续");
          return
        }
      }
      this.$emit('change', {
        regionList: [...regionList, data],
        machineIds: this.machineIds
      })
      // this.regionList.push(data)
      this.$refs['regionDia'].close()
    },
    regionListChange(list) {
      this.$emit('change', {
        regionList: list,
        machineIds: this.machineIds
      })
    },
    openMachineListDia() {
      const omitIds = this.machineTemplateList.reduce((prev, item) => {
        if (item.templateName != this.templateName) {
          prev.push(...item.machineIds)
        }
        return prev
      }, [])
      this.omitIds = omitIds
      this.$refs['machineListDia'].open()
    },
    machineIdsChange(idList) {
      this.$emit('change', {
        machineIds: idList
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.set-title {
  font-weight: bold;
  color: #333333;
  font-size: 16px;
  height: 16px;
  padding-left: 10px;
  border-left: 3px solid #0981ff;
  margin-bottom: 10px;
}
.flex {
  display: flex;
  align-items: center;
}
.flex1{
  flex: 1;
}
.flex-jb {
  justify-content: space-between;
}

.faultBtn {
    width: 280px;
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;
    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981ff;
      border-radius: 20px;
    }
    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }
</style>